<template>
  <div
    v-loading="[clinic.loading, personage.state].includes('loading')"
    class="local_wrap"
  >
    <div v-if="caseDetail['selfDeliverFlag'] == 1" class="title">
      {{ $t("birdie.qxzjjdz") }}
    </div>
    <div class="local_header">
      <div class="identification"></div>
      <p class="local_header_text">{{ $t("birdie.dqjgdz") }}</p>
    </div>
    <div class="header_item">
      <div class="local_item">
        <div class="local_item_left">
          <p class="local_item_left_provinces">
            {{ clinic.provinceName }}-{{ clinic.cityName }}-{{
              clinic.areaName
            }}
          </p>
          <p class="local_item_left_site txt-ellipsis">{{ clinic.address }}</p>
          <p class="local_item_left_iphone_number">
            {{
              clinic.contactWay == "2"
                ? clinic.phoneArea + "-" + clinic.phone
                : clinic.phone
            }}
          </p>
        </div>
        <div class="local_item_right">
          <div
            class="local_item_right_btn flex-x-y-c"
            @click="
              jumpPage({
                indexType: 'model',
                relevanceId: clinic.id,
                clickClinic: true
              })
            "
          >
            {{ $t("birdie.xz") }}
          </div>
          <p
            :class="{ local_item_right_text_lang: isELang }"
            class="local_item_right_text curp"
            @click="
              jumpPage({
                indexType: 'location',
                type: 1,
                editId: clinic.id
              })
            "
          >
            {{ $t("birdie.bj") }}
          </p>
        </div>
      </div>
    </div>
    <p class="organization_init ml30">{{ $t("birdie.gtxd") }}</p>
    <div class="wire"></div>
    <div class="personage_header">
      <div class="flex-y-c">
        <div class="identification"></div>
        <p class="personage_header_text main_theme_color_333">
          {{ $t("birdie.grdz") }}
        </p>
      </div>
      <div
        class="flex-y-c curp"
        @click="
          jumpPage({
            indexType: 'location',
            type: 2
          })
        "
      >
        <img alt="" src="../../../common/imgs/expressage_birdie/local.png" />
        <p class="new_add_personage">{{ $t("birdie.xzgrdz") }}</p>
      </div>
    </div>
    <div
      :style="{ height: scrollbarHei + 'rem', paddingBottom: '0.56rem' }"
      class="personage_content"
    >
      <el-scrollbar style="height: 100%">
        <div
          v-for="(item, index) in personage.list"
          :key="index"
          class="personage_item"
        >
          <div class="local_item_left">
            <p class="local_item_left_provinces">
              {{ item.provinceName }}-{{ item.cityName }}-{{ item.areaName }}
            </p>
            <p class="local_item_left_site txt-ellipsis">{{ item.address }}</p>
            <p class="local_item_left_iphone_number">
              {{
                item.contactWay == 1
                  ? item.phone
                  : `${item.phoneArea}-${item.phone}`
              }}
            </p>
          </div>
          <div class="local_item_right">
            <div
              class="local_item_right_btn flex-x-y-c"
              @click="
                jumpPage({
                  indexType: 'model',
                  relevanceId: item.id,
                  clickClinic: true
                })
              "
            >
              {{ $t("birdie.xz") }}
            </div>
            <p
              :class="{ local_item_right_text_lang: isELang }"
              class="local_item_right_text curp"
              @click="
                jumpPage({
                  indexType: 'location',
                  type: '3',
                  editId: item.id
                })
              "
            >
              {{ $t("birdie.bj") }}
            </p>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div class="local_footer"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { extend, pickBy } from "lodash";
import {
  PublicSendAddressDetailClinic,
  PublicSendAddressList,
  PublicCaseSendAddressCreateModify
} from "common/api/cases";
import { notifyMsg } from "common/js/util";

export default {
  data() {
    return {
      diaHeight: "",
      scrollbarHei: "",
      isELang: localStorage.getItem("user_lang") === "en_US",
      clinic: {
        loading: false
      },
      personage: {
        list: [],
        state: ""
      }
    };
  },
  props: {
    caseDetail: {
      type: Object,
      default: () => ({})
    },
    isReissueFlag: {
      default: false
    },
    caseType: {
      default: "0"
    },
    bindClinic: {
      required: false
    }
  },
  computed: {
    ...mapGetters({})
  },

  methods: {
    ...mapActions({}),
    jumpPage({
      indexType,
      type,
      editId = "",
      relevanceId = "",
      clickClinic = false
    }) {
      // if (type === 2) {
      //   if (this.personage.list.length > 2) {
      //     return notifyMsg(this, "warning", this.$t("birdie.zdsg"));
      //   }
      // }
      let route = this.$route;
      let clinicId = this.caseDetail.clinicId || route.query.addClinicId;
      let resetId = this.caseDetail.clinicId ? "" : route.query.resetId;
      let fn = () => {
        this.$router.push({
          path: route.path,
          query: pickBy(
            extend({}, route.query, {
              indexType,
              [indexType]: type,
              editId,
              relevanceId
            })
          )
        });
      };
      if (
        route.query.caseId &&
        ((clickClinic && +resetId === +clinicId) || (clickClinic && !resetId))
      ) {
        this.relevanceCase(relevanceId)
          .then(() => {
            fn();
          })
          .catch(() => {
            fn();
          });
      } else {
        fn();
      }
    },
    getClinic() {
      if (this.clinic.loading === "loading") return;
      this.clinic.loading = "loading";
      PublicSendAddressDetailClinic(
        this.caseDetail.clinicId || this.$route.query.addClinicId
      )
        .then(data => {
          this.clinic = extend({}, this.clinic, data, { loading: "loadend" });
        })
        .catch(() => {
          this.clinic.loading = "loaderr";
        });
    },
    getPersonage() {
      if (this.personage.state === "loading") return;
      this.personage.state = "loading";
      PublicSendAddressList({
        refCode: localStorage.getItem("userId"),
        refType: "DOCTOR"
      })
        .then(data => {
          this.personage = extend({}, { list: data, state: "loadend" });
        })
        .catch(() => {
          this.personage.state = "loaderr";
        });
    },
    relevanceCase(sendAddressId) {
      let caseId = this.caseDetail.id
        ? this.caseDetail.id
        : this.$route.query.caseId;
      let clinicId = this.caseDetail.id
        ? this.caseDetail.clinicId
        : this.$route.query.addClinicId;
      return PublicCaseSendAddressCreateModify({
        caseId,
        clinicId,
        sendAddressId,
        type: this.caseType
      }).then(() => {
        this.$emit("childCallCaseDetail");
      });
    }
  },

  created() {
    this.scrollbarHei = window.screen.height > 1000 ? 4.75 : 4.7;
    this.$emit("getDiaTitle", this.$t("birdie.qxzjjdz"));
    this.getClinic();
    this.getPersonage();
  }
};
</script>

<style lang="scss" scoped>
.local_wrap {
  height: calc(100% - 58px);
  position: relative;

  .title {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
    margin-left: 30px;
    color: #333333;
  }

  .local_header {
    width: 100%;
    height: 0.22rem;
    display: flex;
    align-items: center;
    padding: 0 30px;

    .identification {
      width: 0.04rem;
      height: 0.22rem;
      background-color: $main_theme_color;
    }

    .local_header_text {
      font-size: 16px;
      margin-left: 10px;
      color: #333333;
    }
  }

  .header_item {
    padding: 0 30px;

    .local_item {
      width: 100%;
      border-radius: 8px;
      box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
      background: #ffffff;
      height: 1.18rem;
      margin-top: 20px;
      padding: 20px 40px 20px 20px;
      display: flex;
      justify-content: space-between;

      .local_item_left {
        width: 72%;

        .local_item_left_provinces {
          text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
          color: #666666;
          font-size: 14px;
          height: 20px;
        }

        .local_item_left_iphone_number {
          color: #666666;
          text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
          font-size: 14px;
          margin-top: 8px;
          height: 20px;
        }
      }

      .local_item_right {
        width: 121px;
        height: 78px;
        border-left: 1px solid rgba(51, 51, 51, 0.12);

        .local_item_right_btn {
          width: 96px;
          height: 36px;
          border-radius: 4px;
          box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
          background: $main_theme_color;
          color: $main_theme_btn_color_white;
          font-size: 14px;
          margin-top: 6px;
          margin-left: 40px;
          cursor: pointer;
        }

        .local_item_right_text {
          font-size: 14px;
          height: 22px;
          width: 96px;
          line-height: 22px;
          color: #333;
          margin-left: 40px;
          margin-top: 12px;
          text-align: center;
        }
      }
    }
  }

  .organization_init {
    color: #666666;
    font-size: 0.12rem;
    margin-top: 10px;
  }

  .wire {
    height: 1px;
    background: #e1e1e1;
    border-radius: 1px;
    margin: 20px 30px 0 30px;
  }

  .personage_content {
    height: 450px;
    padding: 0 0 20px 20px;

    .personage_item {
      border-radius: 8px;
      box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
      height: 1.18rem;
      padding: 20px 40px 20px 20px;
      display: flex;
      justify-content: space-between;
      margin: 0.2rem 0.3rem 0.1rem 0.1rem;

      .local_item_left {
        .local_item_left_provinces {
          text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
          color: #666666;
          font-size: 14px;
          height: 20px;
        }

        .local_item_left_site {
          margin-top: 8px;
          text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
          font-size: 16px;
          color: #333333;
          max-height: 43px;
          line-height: 18px;
        }

        .local_item_left_iphone_number {
          color: #666666;
          text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
          font-size: 14px;
          margin-top: 8px;
          height: 20px;
        }
      }

      .local_item_right {
        width: 121px;
        height: 78px;
        border-left: 1px solid rgba(51, 51, 51, 0.12);

        .local_item_right_btn {
          width: 96px;
          height: 36px;
          border-radius: 4px;
          box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
          background: $main_theme_color;
          color: $main_theme_btn_color_white;
          font-size: 14px;
          margin-top: 6px;
          margin-left: 40px;
          cursor: pointer;
        }

        .local_item_right_text {
          height: 22px;
          width: 96px;
          line-height: 22px;
          font-size: 14px;
          color: #333;
          margin-left: 40px;
          text-align: center;
          margin-top: 12px;
        }
      }
    }
  }

  .personage_header {
    width: 100%;
    height: 0.22rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 22px;
    padding: 0 30px;

    .identification {
      width: 0.04rem;
      height: 0.22rem;
      background-color: $main_theme_color;
    }

    .personage_header_text {
      font-size: 16px;
      margin-left: 10px;
    }

    .new_add_personage {
      color: $main_theme_color;
      font-size: 14px;
    }
  }

  .local_footer {
    width: 100%;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.12);
    background: #ffffff;
    height: 30px;
    position: absolute;
    bottom: -0.3rem;
    left: 0;
  }
}

.local_item_left_site {
  width: 3rem;
  margin-top: 8px;
  text-shadow: 0 0 8px rgba(51, 51, 51, 0.12);
  font-size: 16px;
  color: #333333;
  max-height: 43px;
  line-height: 18px;
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden !important;
}

.local_item_right_text_lang {
  margin-left: 50px !important;
}
</style>
