<template>
  <div v-loading="loading" class="confirm_order">
    <div class="confirm_order_header">
      <div class="confirm_order_header_top">
        <img
          alt=""
          class="confirm_order_header_top_img"
          src="../../../common/imgs/expressage_birdie/send.png"
        />
        <div class="confirm_order_header_top_content">
          <div class="confirm_order_header_top_content_organization flex-y-c">
            <span>
              {{
                get(addressDetail, "senderAddressType") === "CLINIC"
                  ? $t("birdie.jg")
                  : $t("birdie.gr")
              }}
              <span class="ml6">-</span>
            </span>
            <p class="organization ml8">
              {{ get(addressDetail, "senderName") }}
            </p>
            <p class="iphone">
              {{
                get(addressDetail, "senderContactWay") === "2"
                  ? get(addressDetail, "senderPhoneArea") + " - "
                  : ""
              }}{{ get(addressDetail, "senderMobile") }}
            </p>
          </div>
          <p class="confirm_order_header_top_content_provinces">
            {{ get(addressDetail, "senderProvinceName") }}-{{
              get(addressDetail, "senderCityName")
            }}-{{ get(addressDetail, "senderExpAreaName") }}
          </p>
          <p class="confirm_order_header_top_content_location txt-ellipsis2">
            {{ get(addressDetail, "senderAddress") }}
          </p>
          <div class="wire mt30"></div>
        </div>
      </div>
      <div class="confirm_order_header_bottom">
        <img
          alt=""
          class="confirm_order_header_bottom_img"
          src="../../../common/imgs/expressage_birdie/put.png"
        />
        <div class="confirm_order_header_bottom_content">
          <div
            class="confirm_order_header_bottom_content_organization flex-y-c"
          >
            <p class="organization">{{ get(addressDetail, "receiverName") }}</p>
            <p class="iphone">
              {{
                get(addressDetail, "receiverContactWay") === "2"
                  ? get(addressDetail, "receiverPhoneArea") + " - "
                  : ""
              }}{{ get(addressDetail, "receiverMobile") }}
            </p>
          </div>
          <p class="confirm_order_header_bottom_content_provinces">
            {{ get(addressDetail, "receiverProvinceName") }}-{{
              get(addressDetail, "receiverCityName")
            }}-{{ get(addressDetail, "receiverExpAreaName") }}
          </p>
          <p class="confirm_order_header_bottom_content_location txt-ellipsis2">
            {{ get(addressDetail, "receiverAddress") }}
          </p>
        </div>
      </div>
      <img
        alt=""
        class="confirm_order_header_bg"
        src="../../../common/imgs/expressage_birdie/confirm_bg.png"
      />
      <div class="dashes"></div>
    </div>
    <div class="confirm_order_content flex-y-c">
      {{ $t("birdie.smsj") }}
      <el-cascader
        v-model="value"
        :clearable="true"
        :options="options"
        :placeholder="$t('birdie.qxzsmsj')"
        style="width: 3.16rem; margin-left: 0.2rem"
        @change="handleChange"
      ></el-cascader>
    </div>
    <div
      :class="[
        disBtn ? 'dis_confirm_btn' : 'confirm_order_footer_btn',
        'flex-x-y-c',
        'curp'
      ]"
      @click="submitOrder"
    >
      {{ $t("birdie.qrxd") }}
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="innerVisible"
      :width="language === 'en_US' ? '600px' : '454px'"
      append-to-body
      class="core_dia"
    >
      <div class="core_dia_content">
        <img
          :class="{ confirm_err: orderDefeated }"
          :src="orderImg"
          alt=""
          class="confirm_success"
        />
        <p class="core_dia_content_h1 c-333 fz18">
          {{ orderDefeated ? $t("birdie.xdsb") : $t("birdie.ydwcdd") }}
        </p>
        <p v-if="!orderDefeated" class="core_dia_content_h2">
          {{ $t("birdie.nkycx") }}
        </p>

        <div class="core_dia_content_footer">
          <div
            :class="{ cancel_en: language === 'en_US' }"
            class="cancel flex-x-y-c curp"
            @click="backMailDetail"
          >
            {{ $t("common.common.qx") }}
          </div>
          <div
            :class="{ confirm_en: language === 'en_US' }"
            class="confirm flex-x-y-c curp"
            @click="lookOrReSetOrder"
          >
            {{ orderDefeated ? $t("birdie.cxxd") : $t("birdie.ckyxdmx") }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import {
  PublicPickupRegionalCheck,
  PublicLogisticsCreate,
  PublicPickUpOrder
} from "common/api/cases";
import { map, split, each, pickBy, extend, get } from "lodash";
import moment from "moment";
import { notifyMsg } from "common/js/util";

export default {
  props: {
    isReissueFlag: {
      default: false
    }
  },
  data() {
    return {
      innerVisible: false,
      addressDetail: {},
      loading: false,
      value: [],
      today: [],
      tomorrow: [],
      afterDay: [],
      options: [
        {
          value: moment(),
          label: this.$t("birdie.jt"),
          children: this.comTimes
        },
        {
          value: moment().add(1, "days"),
          label: this.$t("birdie.mt"),
          children: this.comTimes
        },
        {
          value: moment().add(2, "days"),
          label: this.$t("birdie.ht"),
          children: this.comTimes
        }
      ],
      startTime: "",
      endTime: "",
      orderDefeated: false,
      disBtn: false
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    }),
    orderImg() {
      return this.orderDefeated
        ? require("../../../common/imgs/expressage_birdie/confirm_err.png")
        : require("../../../common/imgs/expressage_birdie/confirm_success.png");
    }
  },

  methods: {
    ...mapActions({}),
    get,
    backMailDetail() {
      let route = this.$route;
      this.$router.replace({
        path: route.path,
        query: extend({}, route.query, { indexType: "model" })
      });
    },
    lookOrReSetOrder() {
      if (this.orderDefeated) {
        this.$router.back();
      } else {
        let route = this.$route;
        this.$router.replace({
          path: route.path,
          query: { indexType: "place" }
        });
      }
    },
    handleChange(value) {
      let data = split(value[1], ",");
      this.startTime = data[0];
      this.endTime = data[1];
    },
    disposeTimeList(list) {
      let fn = (thatType, item) => {
        if (!item.endTime) {
          this[thatType].push({
            value: "",
            label: this.$t("birdie.lxsyn")
          });
        } else {
          let start = item.startTime.split(" ")[1].slice(0, 5);
          let end = item.endTime.split(" ")[1].slice(0, 5);
          this[thatType].push({
            value: item.startTime + "," + item.endTime,
            label: start + "-" + end
          });
        }
      };
      each(list, item => {
        if (
          moment(item.startTime).format("YYYY-MM-DD") ===
            moment().format("YYYY-MM-DD") ||
          !item.endTime
        ) {
          fn("today", item);
        }
        if (
          moment(item.startTime).format("YYYY-MM-DD") ===
          moment()
            .add(1, "days")
            .format("YYYY-MM-DD")
        ) {
          fn("tomorrow", item);
        }
        if (
          moment(item.startTime).format("YYYY-MM-DD") ===
          moment()
            .add(2, "days")
            .format("YYYY-MM-DD")
        ) {
          fn("afterDay", item);
        }
      });
      this.options = map(this.options, (item, index) => {
        return {
          value: item.value,
          label: item.label,
          children: {
            0: this.today,
            1: this.tomorrow,
            2: this.afterDay
          }[index]
        };
      });
    },
    getDetail() {
      const data = JSON.parse(sessionStorage.getItem("regionalCheckParams"))
        .result;
      if (this.loading === true) return;
      this.loading = true;
      this.addressDetail = data;
      this.disposeTimeList(data.times);
      this.loading = false;
    },
    submitOrder() {
      if (this.disBtn) {
        return;
      }
      if (this.value.length < 1) {
        return notifyMsg(this, "warning", this.$t("birdie.qxgxsj"));
      }
      this.loading = true;
      let {
        receiverName,
        receiverProvince,
        receiverCity,
        receiverAddress,
        receiverExpArea,
        receiverMobile,
        receiverContactWay,
        receiverPhoneArea,
        senderName,
        senderProvince,
        senderCity,
        senderExpArea,
        senderAddress,
        senderMobile,
        senderContactWay,
        senderPhoneArea
      } = this.addressDetail;
      let { startTime, endTime } = this;
      const data = JSON.parse(sessionStorage.getItem("regionalCheckParams"));
      const { orderList, clinicId } = data;
      PublicPickUpOrder(
        pickBy({
          clinicId,
          way: "2",
          receiverName,
          receiverProvince,
          receiverCity,
          receiverArea: receiverExpArea,
          receiverAddress: receiverAddress,
          receiverMobile: receiverMobile,
          receiverContactWay,
          receiverPhoneArea,
          senderName,
          senderProvince,
          senderCity,
          senderArea: senderExpArea,
          senderAddress: senderAddress,
          senderContactWay: senderContactWay,
          senderMobile: senderMobile,
          senderPhoneArea: senderContactWay === "2" ? senderPhoneArea : "",
          orderList,
          pickupStartDatetime: startTime || "",
          pickupEndDatetime: endTime || ""
        })
      )
        .then(data => {
          if (!data.success) {
            let route = this.$route;
            return this.$router.replace({
              path: route.path,
              query: extend({}, route.query, {
                orderCode: data.order.orderCode,
                indexType: "await"
              })
            });
          }
          this.orderDefeated = false;
          this.innerVisible = true;
          this.$emit("reload");
          this.$emit("orderSuccess", 1);
          this.loading = false;
        })
        .catch(() => {
          this.orderDefeated = true;
          this.loading = false;
        });
    }
  },
  components: {},

  created() {
    this.$emit("getDiaTitle", this.$t("birdie.qrddxx"));
    this.getDetail();
  }
};
</script>

<style lang="scss" scoped>
.confirm_order {
  height: 100%;
  padding: 18px 30px 30px 30px;
  position: relative;

  .confirm_order_header {
    width: 100%;
    min-height: 252px;
    background: #ffffff;
    box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
    border-radius: 8px;
    padding: 20px;
    position: relative;

    .confirm_order_header_top {
      display: flex;

      .confirm_order_header_top_img {
        display: block;
        width: 24px;
        height: 24px;
      }

      .confirm_order_header_top_content {
        width: 100%;
        margin-left: 20px;

        .confirm_order_header_top_content_organization {
          color: #333333;
          font-size: 14px;
          margin-top: 2px;
          line-height: 20px;
          height: 20px;

          .organization {
            min-width: 0.6rem;
            max-width: 1.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .iphone {
            min-width: 1rem;
            max-width: 1.5rem;
            margin-left: 32px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .confirm_order_header_top_content_provinces {
          line-height: 20px;
          height: 20px;
          color: #666666;
          font-size: 14px;
          margin-top: 8px;
        }

        .confirm_order_header_top_content_location {
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          margin-top: 4px;
        }
      }

      .wire {
        width: 100%;
        height: 1px;
        background: #e1e1e1;
      }
    }

    .confirm_order_header_bottom {
      display: flex;
      margin-top: 30px;

      .confirm_order_header_bottom_img {
        display: block;
        width: 24px;
        height: 24px;
      }

      .confirm_order_header_bottom_content {
        width: 100%;
        margin-left: 20px;

        .confirm_order_header_bottom_content_organization {
          color: #333333;
          font-size: 14px;
          margin-top: 2px;
          line-height: 20px;
          height: 20px;

          .organization {
            min-width: 0.6rem;
            max-width: 1.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .iphone {
            min-width: 1rem;
            max-width: 1.5rem;
            margin-left: 32px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .confirm_order_header_bottom_content_provinces {
          line-height: 20px;
          height: 20px;
          color: #666666;
          font-size: 14px;
          margin-top: 8px;
        }

        .confirm_order_header_bottom_content_location {
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          margin-top: 4px;
        }
      }
    }

    .confirm_order_header_bg {
      display: block;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .dashes {
      width: 1px;
      height: 91px;
      border: 1px dashed #bfbfbf;
      position: absolute;
      top: 54px;
      left: 30px;
    }
  }

  .confirm_order_content {
    width: 100%;
    height: 80px;
    background: #ffffff;
    box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
    border-radius: 8px;
    margin-top: 20px;
    padding: 0 20px;
    color: #333333;
  }

  .confirm_order_footer_btn {
    width: 216px;
    height: 42px;
    background: $main_theme_color;
    border-radius: 6px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    color: #fff;
    font-size: 16px;
  }

  .dis_confirm_btn {
    width: 216px;
    height: 42px;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    font-size: 16px;
    background: #e5e5e5;
    color: #fff;
  }
}

.core_dia {
  top: 200px !important;
  padding: 0 !important;

  .core_dia_content {
    height: 270px;
    position: relative;

    .confirm_success {
      display: block;
      width: 134px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 33px;
    }

    .confirm_err {
      width: 81px !important;
    }

    .core_dia_content_h1 {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 136px;
    }

    .core_dia_content_h2 {
      width: 100%;
      position: absolute;
      top: 166px;
      color: #999999;
      font-size: 14px;
      text-align: center;
    }

    .core_dia_content_footer {
      position: absolute;
      bottom: 24px;
      display: flex;
      left: 50%;
      transform: translateX(-50%);

      .cancel {
        width: 138px;
        height: 36px;
        background: #ffffff;
        border-radius: 4px;
        font-size: 14px;
        border: 1px solid #bbbbbb;
        color: #333333;
      }

      .cancel_en {
        width: 200px;
      }

      .confirm {
        margin-left: 32px;
        width: 138px;
        height: 36px;
        background: $main_theme_color;
        border-radius: 4px;
        color: #333333;
        font-size: 14px;
        text-align: center;
      }

      .confirm_en {
        width: 200px;
      }
    }
  }
}

/deep/ .el-dialog {
  border-radius: 8px !important;
}

/deep/ .el-dialog__header {
  display: none;
}

/deep/ .el-dialog__body {
  padding: 0 !important;
}

/deep/ .el-scrollbar__view .el-cascader-node .el-cascader-node__postfix {
  display: none !important;
}
</style>
